$(document).ready(function () {


  // stop animations during window resizing
  let eptpResizeTimer;
  $(window).on("resize", function () {
    $("body").addClass("resize-animation-stopper");
    clearTimeout(eptpResizeTimer);
    eptpResizeTimer = setTimeout(() => {
      $("body").removeClass("resize-animation-stopper");
    }, 100);
  });


  // drop open and hide when click outside
  const
    eptpLinks = ".eptp-dropdown-link-js",
    eptpInnerElements = ".eptp-dropdown-js";

  $(document).on("click", eptpLinks, function (e) {
    if ($(this).hasClass("active")) {
      $(eptpLinks).filter('a[href="' + this.hash + '"]').removeClass("active");
      $(eptpInnerElements).filter(this.hash).removeClass("open");
    } else {
      $(eptpLinks).removeClass("active").filter('a[href="' + this.hash + '"]').addClass("active");
      $(eptpInnerElements).removeClass("open").filter(this.hash).addClass("open");
      $(eptpLinks).add(eptpInnerElements).off();
    }
    e.preventDefault();
  }).on("mouseup touchstart keyup", function (e) {
    if ((!$(eptpLinks).add(eptpInnerElements).is(e.target) && $(eptpLinks).add(eptpInnerElements).has(e.target).length === 0) || e.keyCode === 27) {
      $(eptpLinks).removeClass("active");
      $(eptpInnerElements).removeClass("open");
    }
  });


  // open/close nav
  $(document).on("click", ".eptp-nav-burger-js", function (e) {
    $(".eptp-nav-wrap-js").toggleClass("open");
    e.preventDefault();
  });


  // open/close sub nav
  $(document).on("click", ".eptp-nav-links-drop-js", function (e) {
    $(this).toggleClass("active").next(".eptp-nav-sub-js").slideToggle();
    e.preventDefault();
  });


  // open/close sub sub nav
  $(document).on("click", ".eptp-nav-sub-links-drop-js", function (e) {
    $(this).toggleClass("active").next(".eptp-nav-sub-sub-js").slideToggle();
    e.preventDefault();
  });


});
